import React from 'react';
import { Layout, Menu } from 'antd';
import {   useLocation, useNavigate, useParams } from 'react-router-dom';
import './styles.scss';
import Logout from '../../Logout';

const AntHeader = Layout.Header;

function Header() {
  let location = useLocation();
  let navigate = useNavigate();
  let params = useParams();
  return (
    <AntHeader className="Header" router={{ location, navigate, params }}  >
      <Menu
        defaultSelectedKeys={['merchant']}
        mode="horizontal"
        theme="dark"
        className="Nav"
      >
        <Menu.Item key="resell">
            <a href="https://resell-dashboard.hoteos.net/">Resell dashboard</a>
        </Menu.Item>
        <Menu.Item key="merchant">
            <a href="https://merchant-dashboard.hoteos.net/">Merchant dashboard</a>
        </Menu.Item>
      </Menu>
      <div className="Header__rightBlock">
        <Logout />
      </div>
    </AntHeader>
  );
}

export default React.memo(Header);
