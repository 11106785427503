import PlatformItem from './PlatformItem'
import { merchantPlatformsFetch } from "../../Reducers/merchant"
import React, { useEffect, useCallback, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { message } from 'antd'
import './styles.scss';
import ResellButtons from './ResellButtons';

function MerchantPlatforms() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(merchantPlatformsFetch())
    .catch((error) => {
        message.error(error.message || error);
      });
  }, []);

  const afterUpdate = useCallback(() => {
    dispatch(merchantPlatformsFetch());
  }, [dispatch, merchantPlatformsFetch]);

  const list = useSelector((state) => state.merchant.platforms.payload);
  const isLoading = useSelector((state) => state.merchant.platforms.isLoading);

  const resellFilter = /^R-/gm

  const resellList = []
  const othersList = []

  list && list.forEach((item) => {
    item.name.match(resellFilter)
    ? resellList.push(item)
    : othersList.push(item)
  })

  const disableOnButton =  !resellList.find((item) => item.enabled === false )
  const disableOffButton =  !resellList.find((item) => item.enabled === true)

  return (
    <>
      <ResellButtons disableOnButton={disableOnButton} disableOffButton={disableOffButton} afterUpdate={afterUpdate} list={resellList} />
      <div className='MerchantPlatforms'>
        <div className='MerchantPlatforms__column'>
          {
            resellList && resellList.map(platform => <PlatformItem key={platform.pltId} item={platform} isLoading={isLoading} afterUpdate={afterUpdate}/>)
          } 
        </div>
        <div className='MerchantPlatforms__column'>
          {
            othersList && othersList.map(platform => <PlatformItem key={platform.pltId} item={platform} isLoading={isLoading} afterUpdate={afterUpdate}/>)
          } 
        </div>
      </div>
    </>
  )
}

export default MerchantPlatforms
