import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { message, Button } from 'antd';
import { offPlatforms, onPlatforms } from "../../Reducers/merchant"

const ResellButtons = ({disableOnButton, disableOffButton, afterUpdate, list}) => {
  const dispatch = useDispatch();

  const handleOffPlatforms = useCallback(() => {
    const offList = list.map((item) => item = {pltId: item.pltId, enabled: false})

    dispatch(offPlatforms([...offList])).then(() => {
      message.success('All platforms turned off!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update statuses.');
    });
  }, [dispatch, afterUpdate, list]);

  const handleOnPlatforms = useCallback(() => {
    const onList = list.map((item) => item = {pltId: item.pltId, enabled: true})

    dispatch(onPlatforms([...onList])).then(() => {
      message.success('All platforms turned on!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update statuses.');
    });
  }, [dispatch, afterUpdate, list]);

  return (
    <div className='ResellButtons'>
      <Button type="primary" danger disabled={disableOffButton} onClick={handleOffPlatforms}>Resell OFF</Button>  
      <Button type="primary" disabled={disableOnButton} onClick={handleOnPlatforms}>Resell ON</Button> 
    </div>
  )
}

export default ResellButtons;