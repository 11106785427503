import cx from '../../Utils/cx';
import './styles.scss';

function AuthLayout({ children, className }) {
  return (
    <div className='AuthLayout'>
      <div className={cx('AuthLayout__inner', className)}>{children}</div>
    </div>
  );
}

export default AuthLayout;
