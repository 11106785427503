import { Routes, Route } from 'react-router-dom';
import RedirectGuestToSignInPage from '../../Components/RedirectGuestToSignInPage';
import RedirectUserToMainPage from '../../Components/RedirectUserToMainPage';
import RedirectPage from '../../Pages/RedirectPage';
import Merchant from '../../Pages/Merchant';
import './styles.css';

function App() {
  return <div className="App">
    <Routes>
      <Route path="/redirect" element={<RedirectPage />} />
      <Route path="/sign-in" element={<RedirectUserToMainPage/>} />
      <Route path="/" element={<RedirectGuestToSignInPage/>}>
        <Route
          path="/"
          element={<Merchant />}
        />
        <Route
          path="/merchant"
          element={<Merchant />}
        />
      </Route>
    </Routes>
  </div>;
}

export default App;