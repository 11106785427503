import AuthLayout from '../../Components/AuthLayout';
import SingInForm from '../../Components/SingInForm';
import './styles.scss';

function SignIn() {
  return (
    <AuthLayout className='SingIn'>
      <div className='SingIn__card'>
        <SingInForm />
      </div>
    </AuthLayout>
  );
}

export default SignIn;
