import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, message } from 'antd';
import { changePlatformStatus } from "../../Reducers/merchant"

const PlatformItem = ({item, isLoading, afterUpdate}) => {
  const dispatch = useDispatch();

  const handleOnChange = useCallback((enabled, pltId) => {
    dispatch(changePlatformStatus({enabled, pltId})).then(() => {
      message.success('Status have been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('Can\'t update status.');
    });
  }, [dispatch, afterUpdate]);

  return (
    <div className='PlatformItem'>
      <span className='PlatformItem__name'>{item.name}</span>
      <Switch checked={item.enabled} onChange={() => handleOnChange(!item.enabled, item.pltId)} loading={isLoading} />
    </div>
  )
}

export default PlatformItem;