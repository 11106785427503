import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';
import Header from './Header';
import './styles.scss';

const { Content } = Layout;

function MainLayout() {
  return (
    <Layout className='MainLayout'>
      <Layout>
        <Header />
        <Content className='MainLayout__content'>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
}

export default MainLayout;
