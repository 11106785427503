import ApiService from './ApiService';

const MerchantService = {
    fetchPlatforms() {
        return ApiService.get('/platforms');
    },

    changePlatformStatus({enabled, pltId}) {    
        return ApiService.accessPatch('/platform', {pltId, enabled});
    },

    offPlatforms (list) {    
        return ApiService.accessPatch('/platforms', list);
    },

    onPlatforms (list) {    
        return ApiService.accessPatch('/platforms', list);
    },
};

export default MerchantService