import { createAction, createReducer } from '@reduxjs/toolkit';
import frc from 'front-end-common';
import MerchantService from '../Services/MerchantService';

const { getDefaultHandler, getDefaultState } = frc.utils.reducerTools;

export const  merchantPlatformsFetch = createAction('merchant/platforms', (params) => ({
  payload: MerchantService.fetchPlatforms(params),
})) 

export const changePlatformStatus = createAction('merchant/change-platform-status', (params) => ({
  payload: MerchantService.changePlatformStatus(params),
}));

export const offPlatforms = createAction('merchant/off-platform', (params) => ({
  payload: MerchantService.offPlatforms(params),
}));

export const onPlatforms = createAction('merchant/on-platform', (params) => ({
  payload: MerchantService.onPlatforms(params),
}));

const initState = {
  platforms: getDefaultState(),
  changePlatformStatus: getDefaultState(),
  offPlatforms: getDefaultState(),
  onPlatforms: getDefaultState(),
};

export default createReducer(initState, {
  ...getDefaultHandler(merchantPlatformsFetch, 'platforms'),
  ...getDefaultHandler(changePlatformStatus, 'changePlatformStatus'),
  ...getDefaultHandler(offPlatforms, 'offPlatforms'),
  ...getDefaultHandler(onPlatforms, 'onPlatforms'),
});
